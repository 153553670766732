<template>
  <div class="page404">
    <van-empty 
        description="找遍全世界也没找到该页面" 
    />
    <van-button
        class="back-bth"
        type="default"
        plain
        round
        @click="back"
    >返回</van-button>
  </div>
</template>

<script>
  import { reactive, onMounted, toRefs, nextTick } from 'vue'
  import { useRouter } from 'vue-router'
  import { Toast } from 'vant'
  export default {
    name: 'index',
    setup() {
      const router = useRouter();
      const state = reactive({

      })
      onMounted(async () => {
      })
  
      const back = () => {
        router.back();
      }
  
      return {
        ...toRefs(state),
        back
      }
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/style/mixin';
  .page404{
      height: 100%;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .back-bth{
      width:200px;
      color: #b7b7b7;
      border: 1px solid #ddd;
  }
</style>